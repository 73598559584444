import YouTubePlayer from 'youtube-player';

const INTERVAL = 300;

export default class {
    constructor({
        id,
        videoId,
        actions,
        events,
    }) {
        const el = document.getElementById(id);
        const container = el.querySelector('div.iframe');
        const poster = el.querySelector('img.poster');
        // const watch = el.querySelector('div.watch');

        let intervalId;
        let duration = 0;
        let segments = [
            [0, false],
            [5, false],
            [25, false],
            [50, false],
            [75, false],
            [95, false],
            [100, false],
        ];

        const ytPlayer = YouTubePlayer(container, {
            videoId,
            // host: 'https://www.youtube-nocookie.com',
            playerVars: {
                autoplay: 1,
                rel: 0,
            },
        });

        ytPlayer.on('ready', ({ target }) => {
            duration = target.getDuration();
        });

        ytPlayer.on('stateChange', ({ data }) => {
            if (data === 1) { // playing
                poster.classList.add('hidden');
                intervalId = setInterval(() => {
                    ytPlayer.getCurrentTime().then(time => {
                        const percent = Math.round((time / duration) * 100);

                        segments = segments.map(([marker, watched]) => {
                            if (percent >= marker && !watched) {
                                if (typeof dataLayer !== 'undefined') {
                                    dataLayer.push({ // eslint-disable-line no-undef
                                        event: 'getRclassEvents',
                                        eventCategory: 'WatchVideo',
                                        eventAction: videoId,
                                        eventLabel: marker,
                                    });
                                }

                                return [marker, true];
                            }

                            return [marker, watched];
                        });
                    });
                }, INTERVAL);
            }
            if (data === 0) { // ended
                setTimeout(clearInterval(intervalId), INTERVAL);
            }
            if (data === 2) { // paused
                clearInterval(intervalId);
            }
        });

        events.on(actions.closeModal, () => {
            clearInterval(intervalId);
        });
    }
}
